@-webkit-keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

  #simple_loading {
    -webkit-animation: spin 1s infinite linear;
    animation: spin 2s infinite linear;
  }
